import Image from "gatsby-image";
import { rest } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { carouselBreakpoints } from "../helper/carousel";
import CarouselDot from "./carouselDot";

const Members = ({ title, images }) => {
  const responsive = {
    xl: {
      breakpoint: carouselBreakpoints.xl,
      items: 8,
      slidesToSlide: 8,
    },
    l: {
      breakpoint: carouselBreakpoints.l,
      items: 8,
      slidesToSlide: 8,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      items: 6,
      slidesToSlide: 2,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 4,
      slidesToSlide: 2,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 2,
      slidesToSlide: 2,
    },
  };

  return (
    <div className="members">
      <Container fluid>
        <Row>
          <Col>
            <h6>{title}</h6>
          </Col>
        </Row>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={true}
          ssr={true}
          containerClass="frient-carousel"
          itemClass="frient-carousel-item"
          dotListClass="frient-carousel-dots"
          customDot={<CarouselDot />}
          removeArrowOnDeviceType={["xs", "s", "m", "l", "xl"]}
          // partialVisible={false}
        >
          {images
            .filter(image => image.image != null)
            .map(image => (
              <Image fluid={image.image.fluid} alt={image.caption} />
            ))}
        </Carousel>
      </Container>
    </div>
  );
};

/* PropTypes */
Members.propTypes = {
  title: PropTypes.string,
  images: PropTypes.array,
};

Members.defaultProps = {};

export default Members;
