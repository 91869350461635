import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useLocation } from "@reach/router";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";

const PageTitle = ({ title, subtitle, leftAligned }) => {
  const location = useLocation()
  return(
  <div className="pageTitle">
    <Row className={getContainerClasses(leftAligned)}>
      <Col md={8} lg={location.pathname !== "/programme" ? 6 : 12}>
        <h1 className={getContentClasses(leftAligned)}>{title}</h1>
        {subtitle && subtitle.json && (
          documentToReactComponents(subtitle.json)
        )}
      </Col>
    </Row>
  </div>
)};

const getContainerClasses = leftAligned => {
  const classes = ["d-flex", "align-items-center", "justify-content-center"];
  if (leftAligned) {
    classes.push("justify-content-md-start");
  }
  return classes.join(" ");
};

const getContentClasses = leftAligned => {
  const classes = ["text-center"];
  if (leftAligned) {
    classes.push("text-md-left");
  }
  return classes.join(" ");
};

/* PropTypes */
PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
PageTitle.defaultProps = {};

export default PageTitle;
