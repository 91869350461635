/* Global imports */
import React from "react";

const CarouselDot = ({ onClick, ...rest }) => {
  const {
    index,
    active,
    carouselState,
    carouselState: { slidesToShow, deviceType, totalItems },
  } = rest;
  if (slidesToShow >= totalItems) {
    return null;
  }
  return (
    <li
      data-index={index}
      class={`react-multi-carousel-dot ${
        active ? "react-multi-carousel-dot--active" : ""
      }`}
    >
      <button aria-label={`Go to slide ${index}`} onClick={onClick}></button>
    </li>
  );
};

export default CarouselDot;
