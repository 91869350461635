/* Global imports */
import { graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Link from "../helper/link";
import { getValue } from "../helper/translation";

/* Local imports */

/* Component definition */
const Jumbotrons = ({ node_locale, jumbotron }) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  return jumbotron.map((item, index) => (
    <div>
      <Row className="frient--jumbotron">
        <Col
          xs={12}
          md={6}
          className={`d-flex align-items-center ${
            index % 2 !== 0 ? "order-md-12" : ""
          }`}
        >
          <Link
            to={item.link.slug}
            className={`item__image d-block w-100 content mb-md-0`}
          >
            <Image
              className={`item__image d-block w-100 content mb-md-0`}
              fluid={item.image.imageLandscape.fluid}
            ></Image>
          </Link>
        </Col>
        <Col
          xs={12}
          md={6}
          className={`d-flex flex-column justify-content-center align-items-center align-items-md-start text-center text-md-left ${
            index % 2 !== 0 ? "order-md-1" : ""
          }`}
        >
          <h5 className={"item-title"}>{item.title}</h5>
          <p className="item__description">
            {item.description && item.description.description}
          </p>
          <Link
            to={item.link.slug}
            className={"button button--primary button--link"}
          >
            {getValue("actions.readMore", localizedMicrocopy)}
          </Link>
        </Col>
      </Row>
    </div>
  ));
};

export default Jumbotrons;
