/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
import { Col, Row } from "react-bootstrap";

/* Local imports */

/* Component definition */
const TwoColumnsText = ({ title, sub, columnOne, columnTwo }) => {
  return (
    <article className="twoColumnsText">
      <Row>
        <Col>
          <h2 className="title">{title}</h2>
          {sub && <h4 className="subtitle">{sub}</h4>}
        </Col>
      </Row>
      <Row>
        <Col md={6}>{documentToReactComponents(columnOne.json)}</Col>
        <Col md={6}>{documentToReactComponents(columnTwo.json)}</Col>
      </Row>
    </article>
  );
};
/* PropTypes */
TwoColumnsText.propTypes = {};
TwoColumnsText.defaultProps = {};

/* Local utility functions */

/* Styles */

export default TwoColumnsText;
