import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import { Container } from "react-bootstrap";
import slugify from "slugify";
/* Local imports */
import { SLUGIFY_OPTIONS } from "../helper/slugify";
import Link from "../helper/link";
import { getValue } from "../helper/translation";

const PageHeader = ({
  classname,
  title,
  splitTitle = true,
  tags,
  headerSubtitle,
  link,
  queryParameters,
  pastEvent,
  images,
  node_locale,
  bottomAligned = false,
}) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};

  const buildLink = (slug, queryParameters, pastEvent) =>
    pastEvent
      ? `${slug}/${slugify(pastEvent.title, SLUGIFY_OPTIONS)}`
      : `${slug}?${queryParameters}`;

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  return images.length > 1 ? (
    <div className="multipleImagesHeader">
      {images.map((img, index) => (
        <div>
          <BackgroundImage
            Tag="section"
            className={
              bottomAligned
                ? `component pageHeaderBottom`
                : `component pageHeader`
            }
            fluid={img.image.fluid}
          >
            <Container fluid>
              <div className="row">
                <div className="col-12 px-0">
                  <h1 className="mb-3 mb-xl-4 color-white">
                    {title
                      .split(".")
                      .filter(sentence => sentence.length)
                      .map(sentence => (
                        <span>{sentence}.</span>
                      ))}
                  </h1>
                  {link && (
                    <Link
                      className="button button--inverted button--link"
                      to={buildLink(
                        link[index].slug,
                        queryParameters,
                        pastEvent
                      )}
                    >
                      {getValue("actions.seeMore", localizedMicrocopy)}
                    </Link>
                  )}
                </div>
              </div>
            </Container>
          </BackgroundImage>
        </div>
      ))}
    </div>
  ) : (
    <BackgroundImage
      Tag="section"
      className={
        bottomAligned ? `component pageHeaderBottom ${classname}` : `component pageHeader ${classname}`
      }
      fluid={images[0]?.image.fluid}
    >
      <Container fluid>
        <div className="row">
          <div className="col-12 px-0">
            {tags && tags.map(tag => (
              <span className="tag">{tag}</span>
            ))}
            <h1 className="mb-2 mb-xl-2 color-white font-weight-bold">
              {splitTitle ? title
                .split(".")
                .filter(sentence => sentence.length)
                .map(sentence => (
                  <span>{sentence}.</span>
                )) : title}
            </h1>
            {headerSubtitle && (
              <h1 className="subtitle mb-4 mb-xl-4 color-white font-weight-normal">
                {headerSubtitle}
              </h1>
            )}
            {link && (
              <Link
                className="button button--inverted button--link"
                to={buildLink(link.slug, queryParameters, pastEvent)}
              >
                {getValue("actions.seeMore", localizedMicrocopy)}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </BackgroundImage>
  );
};

export default PageHeader;
