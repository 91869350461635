import React from "react";
import { normalizeArticle } from "../helper/normalizer";
import FreeText from "./freeText";
import IconCards from "./iconCards";
import Jumbotrons from "./jumbotrons";
import Members from "./members";
import PageHeader from "./pageHeader";
import PageTitle from "./pageTitle";
import RelatedArticles from "./relatedArticles";
import TwoColumnsText from "./twoColumnsText";

//
// Content type - Component map
//
const dynamicComponents = {
  ContentfulComponentPageHeader: PageHeader,
  ContentfulComponentPageTitle: PageTitle,
  ContentfulComponentIconCardContainer: IconCards,
  ContentfulComponentMembers: Members,
  ContentfulComponentJumbotronContainer: Jumbotrons,
  ContentfulComponentRelatedArticles: RelatedArticles,
  ContentfulComponentFreeText: FreeText,
  ContentfulComponentTwoColumnsText: TwoColumnsText,
};

//
// Live Content Handling
//

export const renderDynamicComponents = (components, lang) => {
  return components.map((component, index) => {
    const DynamicComponent = dynamicComponents[component.internal.type];
    return DynamicComponent ? (
      <DynamicComponent key={index} lang={lang} {...component} />
    ) : null;
  });
};

//
// Preview Content Handling
//

export const renderPreviewComponents = (components, lang) => {
  return components.map((component, index) => {
    const contentType = getContentTypeFromSysId(
      component.sys.contentType.sys.id
    );
    const DynamicComponent = dynamicComponents[contentType];
    component = format(component);
    if (contentType === "ContentfulArticle") {
      component = normalizeArticle(component);
    }
    return DynamicComponent ? (
      <DynamicComponent key={index} lang={lang} {...component} />
    ) : null;
  });
};

const format = entry => {
  if (typeof entry === "object" && entry.hasOwnProperty("fields")) {
    Object.keys(entry.fields).forEach(key => {
      if (Array.isArray(entry.fields[key])) {
        entry.fields[key] = entry.fields[key].map(entry => format(entry));
      } else {
        entry.fields[key] = format(entry.fields[key]);
      }
    });
    return format(entry.fields);
  }
  return entry;
};

const getContentTypeFromSysId = id => {
  const capitalized = id.charAt(0).toUpperCase() + id.slice(1);
  return `Contentful${capitalized}`;
};
